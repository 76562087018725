
@font-face {
    font-family: 'roboto-regular';
    src: local('roboto-regular'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}
  
  
@font-face {
    font-family: 'roboto-bold';
    src: local('roboto-bold'), url(../fonts/Roboto-Bold.ttf) format('truetype');
}
  
  
@font-face {
    font-family: 'roboto-light';
    src: local('roboto-light'), url(../fonts/Roboto-Light.ttf) format('truetype');
}
  

:root{
    --font-vsmall: 1.2vw;
    --font-small: 1.7vw;
    --font-mid: 3vw;
    --font-large: 5.5vw;
    --font-vlarge: 9vw;
    --color-green: #73B75B;
    
}

h1{
    font-size: var(--font-vlarge);
    font-family: roboto-regular;
    margin-bottom: 0;
    padding-bottom: 0;

}

h1.main-title{
    background-color: black;
    color: white;
    height: 9vw;
    line-height: 10vw;
    font-family: roboto-bold;
    padding: 0;
    width: fit-content;
    margin: auto;
    font-size: 14vw;
    
}

h1.main-title .underscore{
    display: inline-block;
    position: relative;
    background-color: white;
    height: 1vw;
    top:8vw;
    overflow: none;
}

h1.sub-title{
    position: relative;
    font-size: 14vw;
    padding: none;
    margin: none;
    top: -8vw;
}

h1.sub-title a{
    text-decoration: none;
    color: black;
}


body, html{
    height: 100%;
    min-height: 110vw;
    width: 100%;
    font-family: roboto-regular;
    text-align: center;
    background-color: white;
}

button {
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: .5vw 2vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: var(--font-vsmall);
    margin: .25vw .45vw;
    border-radius: 14px;
    text-transform: uppercase;
    font-family: 'roboto-bold';
    box-shadow: 1px 1px 1px  rgba(0,0,0,0.25);

  }

  button:hover{
    background-color:black;
    color: white;

  }

  .App{
      height: 100vh;
  }

  .sub-title{
    text-transform: uppercase;
    font-family: 'roboto-bold';
    font-size: var(--font-small);
  }

  .sub-sub-title{
    text-transform: uppercase;
    font-family: 'roboto-bold';
    font-size: var(--font-vsmall);
  }

.one-word-per-line {
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;
}



.clickable{
    cursor: pointer;
}

.info-button img{
    position: fixed;
    bottom: 5vw;
    left:5vw;
    cursor: pointer;
    -webkit-box-shadow: 4px 7px 10px 3px rgba(0,0,0,0.38); 
    box-shadow: 4px 7px 10px 3px rgba(0,0,0,0.38); 
    border-radius: 50%;
}

.signup-button {
    position: fixed;
    bottom: 0vw;
    right:0vw;
    height: 80px;
    width: 80px;
    cursor: pointer;
    border-radius:80px 0 0 0;
    margin-top: 40px;
    border: 1px black dotted;
    border-bottom: none;
    border-right:none;
    text-transform: uppercase;
    background-color: #F3F3F3;
    text-align: center;
    text-justify: auto;
    box-shadow: 6px 10px 14px 6px rgba(0,0,0,0.38); 

}

@keyframes grow{ 
    0% { height: 80px;
        width: 80px;
        font-size: .001vw
    }
    50% {
        font-size: .001vw 
    }
    100%{ height: 50vh;
        min-height: 300px;
        width: 50vh;
        min-width: 300px;
        border-radius:0 0 0 0;
        font-size: 12pt;
  
    }

}

.signup-button.form{
    position: fixed;
    bottom: 0vw;
    right:0vw;
    animation: grow 1s forwards;
    cursor: inherit;
    
    border: 1px black dotted;
    border-bottom: none;
    border-right:none;
    text-transform: uppercase;
    background-color: #F3F3F3;
    text-align: center;
    text-justify: auto;
    box-shadow: 6px 10px 14px 6px rgba(0,0,0,0.38); 
    z-index: 9999;

}

.signup-button .signup-text{
    margin-top: 9vw;
    width:70px;
    margin: auto;
    margin-top: 26pt;
    margin-left: 10px;
    font-weight: bold;
    font: roboto-bold;
    font-size: 12pt;
    text-align: center;
}

.signup-button.form p{
    width: 90%;
    margin:auto;
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.signup-button.form input{
    -webkit-appearance: none; -moz-appearance: none;
  display: block;
  margin: 0;
  width: 90%; height: 40px;
  margin: auto;
  line-height: 40px; font-size: 17px;
  border: 1px solid #bbb;
}

.signup-button.form button{
  -webkit-appearance: none; -moz-appearance: none;
  display: block;
  margin: 0;
  width: 90%; height: 40px;
  margin: auto;
  padding: none;
  line-height: 30px; font-size: 17px;
  border: 1px solid #bbb;
  margin-top: 1vw;
}

.signup-button.form .close button{
    position: absolute;
    bottom: 1vw;
    left: 5%;
  }
  

.blueprints{
    margin-top: 4vw;
}


.blueprints .hbar{
    display: inline-block;
    margin-left: 1vw;
    margin-right: 1vw;
    font-size: var(--font-mid);
    font-weight: bold;
}

.blueprints a{
    font-size: var(--font-mid);
    text-decoration: none;
    color: grey;
    font-weight: bold;
}

.blueprints img{
    height: calc(var(--font-mid) * 2);
    margin-bottom: calc(var(--font-mid) / -1.5)
}

button.ar-button {
    border-radius: 0px !important;
    font-size: var(--font-mid);
    margin-top: 3vw;
}

@media (min-width: 767px){


button.ar-button {
    font-size: var(--font-small);
}
    h1.sub-title{
        font-size: 6vw;
        top: -4vw;
    }
    h1.main-title{
        line-height: 4.25vw;
        font-size: 6vw;
        height: 4vw;

    }
    
    h1.main-title .underscore{
        height: .5vw;
        top:3.5vw;
        overflow: none;
        height: 4vw;

    }

    .info-button img{
       
        bottom: 1vw;
        left:1vw;
        cursor: pointer;
     
    }

   
    
    
}
