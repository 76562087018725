.three-column-with-descr, .two-column-with-descr{
    
    width: 100%;
    display:flex;
    margin-top: 10vw;
}

.three-column-with-descr .panel{
    height:100%;
    align-self: center;
    width: 15%;

}

.panel.left{
    margin-left: 4.5%;
    padding-left: 5%;
}


.panel.right{
    margin-right: 4.5%;
    padding-right: 5%;
}

.three-column-with-descr .cel{
    width: 26%;
    align-items: center;
    flex-shrink: 0;
    margin-top: 2vw;
    
}

.three-column-with-descr .cel .konvajs-content{
    margin:auto
}

.three-column-with-descr .cel .konvajs-content canvas{
    margin:auto
}

.three-column-with-descr  .content{
    width: 69%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}   



.two-column-with-descr .panel{
    height:100%;
    align-self: center;
    width: 10%;
}


.two-column-with-descr .cel{
    width: 50%;
    align-items: center;
    flex-shrink: 0;
    margin-top: 2vw;
    
}

.two-column-with-descr .cel .konvajs-content{
    margin:auto
}

.two-column-with-descr .cel .konvajs-content canvas{
    margin:auto
}

.two-column-with-descr  .content{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 15%;
    margin-right: 15%;

}   