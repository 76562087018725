.modal{
    position: fixed;
    top:5vw;
    left:50vw;
    z-index: 15;
    background-color: white;
    transform: translate(-50%, 0);
    width: 45vw;
    height: 55vw;
    border: 1px solid black;
    -webkit-box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 4px 4px 4px  0px rgba(0,0,0,0.25);
    box-shadow: 4px 4px 4px  rgba(0,0,0,0.25);
}

.modal-overlay{
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height: 100vh;
    background-color: lightgrey;
    opacity: .95;
    z-index: 10;
}



.modal .xy-viewport .xy-content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    margin: auto;
    text-align: center;
    height: 25vw;
    margin-top: 3vw;
}

.xy-viewport{
    width: 100%;
    height: 25vw;
}



.xy-content .konvajs-content {
    margin: auto;
    margin-top: 4vw;
}
    
.xy-content .konvajs-content canvas{
    margin-top: auto;
}

.modal .handle-close{
    width:98%;
    text-align: right;
    margin-top: .7vw;
    font-family: roboto-bold;
    font-weight: bold;
    font-size: var(--font-small);
    cursor: pointer;
}

.modal .handle-up{
    position: absolute;
    transform: rotate(-90deg) scaleY(3) ;
    left: calc(50% - var(--font-small)/2);
    top: 3vw;
    font-size: var(--font-small);
    font-family: roboto-regular;
    cursor: pointer;

}

.modal .handle-left{
    position: absolute;
    transform:  scaleY(3) ;
    left: 4vw;
    top: 16vw;
    font-size: var(--font-small);
    font-family: roboto-regular;
    cursor: pointer;

}

.modal .handle-right{
    position: absolute;
    transform:  scaleY(3) ;
    right: 4vw;
    top: 16vw;
    font-size: var(--font-small);
    font-family: roboto-regular;
    cursor: pointer;

}


.modal .handle-down{
    position: absolute;
    transform:  rotate(90deg) scaleY(3) ;
    left: calc(50% - var(--font-small)/2);
    top: 34vw;
    font-size: var(--font-small);
    font-family: roboto-regular;
    cursor: pointer;

}

.modal .disabled{
    color: lightgrey;
    cursor: default;

}

.modal .name{
    position: absolute;
    top: 40vw;
    left:0;
    right: 0;
    text-align: center;
    font-size: var(--font-small);
    font-family: roboto-bold;
    text-transform: uppercase;
    font-size: var(--font-mid);

}

.modal .subName{
    position: absolute;
    top: 44vw;
    left:0;
    right: 0;
    text-align: center;
    font-size: var(--font-small);
    font-family: roboto-light;
    text-transform: uppercase;
    font-style: italic;
}

.modal .actions{
    position: absolute;
    top: 50vw;
    width: 100%;
    text-align: center;
}