.carousel {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    margin-top: -6vw;
    width:100%;
   
}

.carousel .carousel-item{
    padding: 4vw;
    width: 55%;
    overflow: hidden;
    cursor: pointer;
    flex-grow:0;
    flex-shrink:0;

}


.preselected .carousel .carousel-item {
    margin-top: -10vw;
    opacity: .2;
    cursor: auto;
    width: 50vw !important;
    cursor: pointer;
}


.preselected .carousel .carousel-item.selected {
    margin-top: -10vw;
    opacity: 1;
    cursor: auto;
    cursor: pointer;
}

.preselected .carousel{
    margin-top: 2vw;
}

.carousel .carousel-item .konvajs-content{
    margin:auto;
    pointer-events: none;
}

.carousel .carousel-item .title{
    display: block;
    margin-top: 4vw;
    text-transform: uppercase;
    font-family: 'roboto-bold';
    font-size: var(--font-mid);
}

.carousel .carousel-item .descr{
    display: block;
    margin-top: 1vw;
    font-family: 'roboto-light';
    font-style: italic;
    font-size: var(--font-small);
}

.carousel .left-handle,.right-handle{
    position: absolute;
    font-family: 'roboto-light';
    font-size: 15vw;
    top: 53vw;
    left: 12vw;
    z-index: 3;
    width: 1vw;
    transform: scaleY(2);
    cursor: pointer;
    text-align: center;
    user-select: none;
}

.carousel .right-handle{

    left: 80vw;

}

@media (min-width: 767px){
    .carousel{
        margin-top: -6vw;
    }
    .carousel .carousel-item{
        width: 15%;
    }


.carousel .left-handle,.right-handle{

    font-size: 6vw;
    top: 22vw;
    left: 38vw;
    z-index: 3;
    width: 1vw;

}

.carousel .right-handle{

    left: 62vw;

}
    
}