.flex{
    display: flex;
    width:100%;
    justify-content: center;
}

.flex-h{
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
margin: auto;
flex-direction: row 
}

.flex-h > div{
 margin: 2vw;
}

.flex-h .konvajs-content{
    margin: auto;
}

.flex-h .sub-title{
   margin-top: 2vw;
   text-align: center;
}