.about{
    text-align: left;
    position: absolute;
    width:100vw;
    background-color: white;
    z-index: 99999;
    font-size: 3.5vw;
    padding: 1vw;
    margin:none;
}

.about-content{
    padding:1vw;
    width:95vw;
}

.about .close{
    background-color: grey;
    width: 100%;
    margin-left: -1vw;
    height: 7vw;
    text-align: center;

}

.about .close .close-button{
    transform: rotate(90deg) scaleY(4);
    font-size: 7vw;
    font-weight: bold;

}

.about a{
    text-decoration: none;
    font-weight: bold;
    color: grey;
}

.about li {
    text-decoration: none;
    line-height: 1.5em;
}

.about.hidden{
    animation: slide-out 0.5s forwards;
    display: none;
} 


@keyframes slide-in {
    0% { transform: translateY(0%); }
    100% { transform: translateY(-20%); }
}

    
@keyframes slide-out {
    0% { transform: translateY(-20%); }
    100% { transform: translateY(0%); }
}


.about.show{
    animation: slide-in .5s forwards;
    left:0;
}

@media (min-width: 767px){
    .about{
 
        font-size: 2vw;
       
    }

    .about .close{
 
        height: 3.5vw;
    
    }
    
    .about .close .close-button{
        font-size: 3.5vw;
    
    }
}

