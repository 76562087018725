.details-image img{
    width: 100%;
}
.details-image{
    width:100%;
}

.details-detail{
    width: 100%;
    text-align: center;
    font-size: var(--font-mid);
}

.details{
    margin-top: -5vw;
}

@media (min-width: 767px){
    .details{
        display: flex;
        width:100%;
        justify-content: center;
        margin-top: 2vw;
    }

    .details-image{
        width:75%;
    }
    .details-detail{
        width: 50%;
        text-align: left;
        font-size: var(--font-small);
        padding-top: 10vw;
        
    }
}